<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
	label: {
		type: String,
		required: false,
	},
});

const emit = defineEmits(['toggleSearch']);

const labeled = props.label || t('shared.buttons.buscar');

</script>
<template>
	<Button
		type="button"
		icon="pi pi-search"
		:label="labeled"
		class="p-button-search"
		raised
		@click="emit('toggleSearch')"
	/>
</template>
