export const seguimientos = () => {
	return {
		menuModel: {
			editar: 'Ver / Editar Seguimiento',
			cancelar: 'Cancelar',
			eliminar: 'Eliminar',
			cliente: 'Ver Cliente',
			siniestro: 'Ver Siniestro',
		},
		confirm: {
			board: {
				header: '¿Desea eliminar el tablero?',
				message: 'Esto eliminará todas las columnas y sus respectivos tickets',
			},
			column: {
				header: '¿Desea eliminar la columna?',
				message: 'Esto eliminará todos los tickets correspondientes',
			},
		},
		toast: {
			success: {
				summary: 'Ticket Guardado',
				detail: 'El ticket ha sido guardado correctamente',
			},
			error: {
				summary: 'Error al guardar el Ticket',
				detail: 'No se ha podido guardar el ticket correctamente',
			},
			delete: {
				summary: 'Ticket Eliminado',
				detail: 'El ticket ha sido eliminado correctamente',
			},
			cancel: {
				summary: 'Ticket Cancelado',
				detail: 'El ticket ha sido cancelado correctamente',
			},
		},
		nuevo_seguimiento: 'Nuevo seguimiento',
		components: {
			commentDisplayer: {
				p: 'el {created} por {nombre}',
			},
			table: {
				menuModel: {
					editar: 'Editar Seguimiento',
					borrar: 'Borrar Seguimiento',
					cerrar: 'Cerrar Seguimiento',
				},
				columns: {
					estado: 'Estado',
					responsable_participantes: 'Responsable / Participantes',
					clasificacion: 'Clasificación',
					referencia: 'Referencia',
					titulo_descripcion: 'Titulo / Descripción',
					ultimo_comentario: 'Último comentario',
					recordatorio: 'Recordatorio',
					id: 'ID',
				},
				sinRecordatorios: 'Sin Recordatorios',
				tooltip:
					'Este seguimiento no se está mostrando en el Calendario de Eventos hasta tanto no le asignes una fecha de recordatorio',
			},
			kanbanItem: {
				card: {
					header: {
						fecha: 'Creado el {fecha}',
						autor: 'por {autor}',
					},
					subtitle: {
						cliente: 'Cliente: {nombre}',
						poliza: 'Póliza: {numero}',
						siniestro: 'Siniestro: {numero}',
					},
					footer: {
						tooltip: {
							externo: 'Ticket Externo',
							adjunto: 'Tienes {numero} adjuntos.',
							comentario: 'Hay {numero} comentarios',
							recordatorio: 'Recordatorio',
						},
					},
				},
				comentarios: {
					header: 'Comentarios',
				},
			},
			search: {
				title: 'Búsqueda de Seguimientos',
				inputs: {
					externalProducer: 'Responsable (productor externo)',
					gte: 'Fecha de Creación mayor a',
					lte: 'Fecha de Creación menor a',
				},
			},
			fields: {
				asignadoA: 'Asignado a',
				referidoA: 'Referido a',
			},
			form: {
				addEdit: {
					title: {
						new: 'Nuevo ticket',
						edit: 'Editar ticket {ticket}',
					},
					inputs: {
						titulo: 'Título',
						clasificacion: 'Clasificación',
						fecha_recordatorio: 'Fecha Recordatorio',
						hora_recordatorio: 'Hora Recordatorio',
						participantes: 'Participantes',
						descripcion: 'Descripción',
						comentarios: 'Comentarios',
					},
					field: {
						adjuntos: 'Adjuntos',
					},
				},
				referidoCard: {
					header: 'Referido a: {nombre} ({value})',
					polizasActivas: 'Pólizas Activas:',
					tipoPersona: 'Tipo de Persona:',
					tipoIva: 'Tipo de Condición IVA:',
					tipo: 'Tipo:',
					motivo: 'Motivo:',
					estado: 'Estado:',
					numero: 'Número:',
					fecha: 'Fecha:',
				},
				addEditBoardForm: {
					title: {
						new: 'Nuevo tablero',
						edit: 'Editar tablero {board}',
					},
				},
			},
		},
	};
};
