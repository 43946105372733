import { accidentes_pasajeros } from './assets/accidentes_pasajeros';
import { aeronavegacion } from './assets/aeronavegacion';
import { art } from './assets/art';
import { casco } from './assets/casco';
import { granizo } from './assets/granizo';
import { robo } from './assets/robo';

export const bienes = () => {
	return {
        ubicacion_riesgo:'Ubicación del riesgo',
		dado_de_baja_en_endoso: 'Dado de baja en endoso {msg}',
		dado_de_alta_en_endoso: 'Dado de alta en endoso {msg}',
		dar_de_baja_en_endoso: 'Dar baja en este endoso',
		select_to_modify: 'Seleccionar bien a modificar o eliminar',
		toast_modificar_eliminar:
			'Ya podés modificar o dar de baja el bien seleccionado haciendo click derecho en el bien',
		previos: 'Modificar bienes previos',
		bienes_modificados: 'Bienes Modificados',
		iniciales: 'Bienes iniciales',
		del_endoso: 'Bienes del endoso {msg}',
		add: 'Agregar Bien {msg}',
		premio: 'Premio del bien asegurado',
		prima: 'Prima del bien asegurado',
        producto: 'Producto / Descripción',
        set_principal: 'Marcar como cobertura principal',
        is_principal: 'Cobertura principal',
		search: 'Buscar Bien',
        solo_activos: 'Sólo activos',
		subir_flota: 'Subir flota',
		processing_flota: 'Procesando archivo de flota de vehículos en paralelo. Se completará en minutos.',
		observaciones: 'Observaciones',
		flota: {
			header: 'Archivo para flotas',
			text1:
				'Se intentarán cargar los vehículos del archivo que seleccione a la póliza o el endoso que esté editando.',
			text2: 'Pueden descargar el modelo de archivo (.xls - archivo formato excel 97) >> ',
			capsaqui: 'AQUI',
			text3:
				'En los datos tipificados (Tipo Vehiculo, etc), deben ingresar el texto tal como se muestra en los desplegables del sistema',
		},
		form: {
            accesorios: 'Accesorios',
			modify: 'Modificar bien',
			unload: 'Dar de baja el bien',
			confirm_unload: '¿Dar de baja el bien seleccionado?',
			edit: 'Editar bien',
			delete: 'Borrar bien',
			bien_eliminado: 'Bien eliminado',
			confirm_delete: '¿Confirma eliminar el bien seleccionado? Esta acción no se puede deshacer',
			missing_cp: 'Debe completar el Código Postal de la Ubicación',
			add_inline_coverage: 'Agregar Cobertura',
			remove_coverage: 'Quitar Cobertura',
			add_coverage: 'Crear cobertura y agregar al bien actual',
			new_coverage_for_ramo: 'Nueva Cobertura para Ramo {msg}',
			dialog: {
				edit: 'Editar Bien: {msg}',
				add: 'Nuevo Bien asegurado',
			},
		},
		assets: {
			automotor: {
				title: 'Automotor',
				otro: 'Otro (ingresar si el vehículo no aparece en el desplegable)',
				cero_kilometro: '0 km',
				prima_rc: 'Prima RC',
				cobertura_principal: 'Cobertura principal',
				motor: 'Motor',
				chasis: 'Chasis',
				patente: 'Patente',
				tipo_uso: 'Uso del vehículo',
				tipo_origen: 'Orígen',
				franquicia: 'Franquicia',
				titular: 'Titular / Conductor',
				categoria_rc: 'Límite RC',
				tipo_vehiculo: 'Tipo vehículo',
				tipo_carroceria: 'Carrocería',
			},
			vida: {
				title: 'Vida',
				numero_documento: 'Número de Documento',
				fecha_alta: 'Fecha de Alta',
				fecha_baja: 'Fecha de Baja',
				fecha_nacimiento: 'Fecha de Nacimiento',
				sueldo: 'Sueldo',
				conyuge_id: 'Cónyuge',
				capital_muerte: 'Capital por Muerte',
				importe_capital: 'Importe Capital',
				sexo: 'Sexo',
				actividad: 'Actividad',
				fuma: 'Fuma',
				hijos: 'Hijos',
				telefono: 'Teléfono',
				life_asset_beneficiaries: {
					title: 'Beneficiarios',
					estatura: 'Estatura',
					registro_conducir: 'Registro de conducir',
					numero_documento: 'Número de documento',
					peso: 'Peso',
					vencimiento_registro_conducir: 'Vencimiento Registro de conducir',
					tipo_persona: 'Tipo de Persona',
					pais_nacimiento: 'Nacionalidad',
					fecha_nacimiento: 'Fecha Nacimiento',
					tipo_estado_civil: 'Estado Civil',
					tipo_profesion: 'Profesión',
					obra_social: 'Obra Social',
				},
			},
			caucion: {
                vigencia_abierta_message: 'Se ha cambiado la póliza a vigencia abierta',
                vigencia_cerrada_message: 'Se ha cambiado la póliza a vigencia cerrada',
				title: 'Caución',
				tipo_caucion: 'Tipo Caución', // drodown - ver opciones
				dependencia: 'Dependencia',
				cuit: 'CUIT',
				despachante: 'Despachante',
				costo_alquiler: 'Costo Alquiler',
				costo_expensas: 'Costo Expensas',
				porcentaje_aumento: 'Porcentaje Aumento',
				duracion_contrato: 'Duración Contrato',
				observaciones: 'Observaciones',
			},
			art: art(),
			accidentes_pasajeros: accidentes_pasajeros(),
			aeronavegacion: aeronavegacion(),
			casco: casco(),
			granizo: granizo(),
			robo: robo(),
		},
	};
};
