//import { ref } from 'vue'
import { getLocale } from '@i18n/localeService';

export function parseDateArgentinianFormat(dateString) {
	// Split the input string by '/'
	const parts = dateString.split('/');

	// Extract day, month, and year components
	let day = parseInt(parts[0], 10);
	const month = parseInt(parts[1], 10) - 1; // Month in JavaScript Date object is zero-based (0 for January)
	const year = parseInt(parts[2], 10);

	// If day part has only one digit, prepend '0' to make it two digits
	if (day < 10) {
		day = '0' + day;
	}

	// Create a new Date object
	const date = new Date(year, month, day);

	return date;
}

export function isValidArgentinianDateFormat(dateString) {
	// Regular expression pattern to match 'dd/mm/YYYY' format
	const dateFormatPattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

	// Check if the string matches the pattern
	return dateFormatPattern.test(dateString);
}

export function getDateObject(date) {
	const stringDate = getDateValue(date);
	switch (getLocale()) {
		case 'es-ar':
			if (isValidArgentinianDateFormat(stringDate)) {
				return parseDateArgentinianFormat(stringDate);
			} else {
				let dateParts = stringDate.split('/');
				return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
			}
		default:
			return new Date(stringDate);
	}
}

export function useLocaleDate(date) {
	// cheatsheet: https://devhints.io/wip/intl-datetime
	const options = {
		day: 'numeric',
		month: '2-digit',
		year: 'numeric',
	};

	// ese timezone fixea el problema del 1 day off
	try {
		const transformedDate = new Intl.DateTimeFormat(getLocale(), options).format(
			new Date(date + 'T00:00-0800')
		);
		return { transformedDate };
	} catch {
		return date;
	}
}

export function generateArrayOfYears(num) {
	var max = new Date().getFullYear();
	var min = max - num;
	var years = [];

	for (var i = max; i >= min; i--) {
		years.push(i);
	}
	return years;
}

const dateRegex = /^\d{1,2}\/\d{2}\/\d{4}$/;
const isValidDateFormat = (input) => dateRegex.test(input);

export function getDateValue(date) {
    let ret = null
    if (date){
        if (date instanceof Date) ret =  date.toLocaleDateString(getLocale());
        else if (isValidDateFormat(date)) ret = date;
        else ret = useLocaleDate(date?.substring(0,10)).transformedDate;
    }
    //console.log(ret)
    return ret
}

export function useCalculateAge(date) {
	const ageDifMs = Date.now() - new Date(date).getTime();
	const ageDate = new Date(ageDifMs);
	const age = Math.abs(ageDate.getUTCFullYear() - 1970);
	return { age };
}

export function getDaysDiff(date) {
	const timedif = Date.now() - new Date(date).getTime();
	const daysdif = timedif / (1000 * 3600 * 24);
	return daysdif;
}

export function isValidDate(date) {
	return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}

// Update the isIsoDate function for better reliability
export function isIsoDate(str) {
    if (typeof str !== 'string') return false;
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z$/.test(str);
}

export function addMonths(date, months) {
	var d = date.getDate();
	var nd = new Date(date.getTime());
	nd.setMonth(date.getMonth() + months);
	if (nd.getDate() !== d) {
		nd.setDate(0);
	}
	return nd;
}

export function extractDateAndTime(date) {
	let newDate = Date.parse(date);
	let dateObject = new Date(newDate);
	let dateFormatted = dateObject.toLocaleDateString(getLocale());
	let timeFormatted = dateObject.toLocaleTimeString(getLocale());
	return `${dateFormatted} - ${timeFormatted}`;
}

export const extractTimeOnly = (date) => {
    //console.log('Input date:', date);
    if (isValidDate(date)) {
        // Use toLocaleTimeString to get the time in the local time zone
        return date.toLocaleTimeString(getLocale(), { hour: '2-digit', minute: '2-digit', hour12: false });
    }
    if (typeof date === 'string') {
        if (isIsoDate(date)) {
            // Create a new Date object and use toLocaleTimeString
            const dateObj = new Date(date);
            return dateObj.toLocaleTimeString(getLocale(), { hour: '2-digit', minute: '2-digit', hour12: false });
        }
        // Handle non-ISO date strings (e.g., "14:30")
        const timeParts = date.split(':');
        if (timeParts.length === 2) {
            return date;
        }
    }
    console.log('Unhandled date format en extractTimeOnly:', date);
    return date;
};

export const extractDateOnly = (date) => {
	try {
		if (!isValidDate(date))
			if (date.split('/').length > 1)
				date = new Date(date.split('/')[2], date.split('/')[1] - 1, date.split('/')[0]);
			else date = new Date(date.split('T')[0]);
		return date.toISOString().split('T')[0];
	} catch (e) {
		return date;
	}
};

export function formatDateString(str) {
	if (typeof str === 'string') {
		if (str[4] === '-') {
			let parts = str.substring(5).split('-');
			let formattedDate = parts[1] + '-' + parts[0];
			return formattedDate;
		} else if (str[2] === '/' && str[5] === '/') {
			let parts = str.split('/');
			if (parts.length === 3) {
				let formattedDate = parts[0] + '-' + parts[1];
				return formattedDate;
			}
		}
	} else return str;
}

export const toLocaleDate = (dateString) => {
	const date = new Date(dateString);

	const options = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		timeZone: 'America/Argentina/Buenos_Aires',
	};

	return date.toLocaleDateString('es-AR', options);
};

export const getNow = () => {
	return new Date();
};

export const getOneMonthFromNow = () => {
	let today = getNow();
	today.setMonth(today.getMonth() + 1);
	return today;
};

export const subtractYears = (date, years) => {
	date.setFullYear(date.getFullYear() - years);
	return date;
};

export const subtractMonths = (date, months) => {
	date.setMonth(date.getMonth() - months);
	return date;
};

export const subtractWeeks = (date, weeks) => {
	date.setDate(date.getDate() - 7 * weeks);
	return date;
};

export const getOneWeekFromNow = () => {
	let today = getNow();
	today.setDate(today.getDate() + 7);
	return today;
};

export const getLastWeek = () => {
	const today = new Date();
	return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
};
